const QuestionData = () => [
  {
    title: 'question-title-1',
    content: 'question-content-1',
  },
  {
    title: 'question-title-2',
    content: 'question-content-2',
  },
  {
    title: 'question-title-3',
    content: 'question-content-3',
  },
  {
    title: 'question-title-4',
    content: 'question-content-4',
  },
  {
    title: 'question-title-5',
    content: 'question-content-5',
  },
  {
    title: 'question-title-6',
    content: 'question-content-6',
  },
]

export default QuestionData