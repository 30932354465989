const getInitialData = () => [
  {
    id: 1,
    name: "PT. Ikan Segar Indonesia",
    description: "desc_pt_ikan_segar_indonesia",
    certified: "2023-08-24",
    sector: "Marine Fisheries and Aquaculture",
    location: "Jakarta Utara, Indonesia (ID)",
    category: "Standard Blue Economy Company",
    address:
      "Gedung Pasar Ikan Modern Muara Baru (PIM), Jakarta. (Operasional). Kirana Tower II, Level 10-A, JL. Boulevard Timur No. 88 Kelapa Gading, Jakarta Utara.",
    logo: "https://ibb.co.com/b2j7QWG",
    certificateStatus: "certificateStatus",
    link: "https://ikansegar.co.id/",
  }
];

export default getInitialData;
